import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import './customcalender.scss';
import Headhome from './headhome';
const { datesGenerator } = require('dates-generator');

// const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

const Container = styled.div`
  width: 300px;
  border: 1px solid black;
  margin: 0 auto;
//   box-shadow: 10px 10px 0px black;
`

const MonthText = styled.div`
  font-size: 26px;
  font-weight: bold;
  text-align: center;
`

const DateCell = styled.div`
  text-align: center;
  padding: 5px 5px;
  font-size: 1.07rem;
  
  color: ${({ isPast }) => (isPast? '#aaa':'#333')};
  // color: ${({ isselected, iscurrentMonth }) => (isselected ? 'pink' : iscurrentMonth ? '#000000' : '#E5E5E5')};
  // border: ${({ isselected }) => (isselected ? '1px solid pink' : 'none')};
  // border-radius: ${({ isselected }) => (isselected ? '50%' : '0')};
  cursor: pointer;
  position: relative;

  &:hover {
    color: white;
    background-color: #aaa;
    border-radius: 70%;
    opacity: 0.2;
    // &::after {
    //   content: attr(data-tooltip);
    //   position: absolute;
    //   top: 100%;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   background-color: black;
    //   color: white;
    //   padding: 5px;
    //   border-radius: 15%;
    //   white-space: nowrap;
    //   opacity: 0.8;
    //   z-index: 1;
    // }
  }
`;

const CustomCalender = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dates, setDates] = useState([]);
  const [calendar, setCalendar] = useState({
    month: selectedDate.getMonth(),
    year: selectedDate.getFullYear(),
  });


  useEffect(() => {
    const body = {
      month: calendar.month,
      year: calendar.year
    };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);

    setDates([ ...dates ]);
    setCalendar({
      ...calendar,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear
    });
  }, [])

  const onClickNext = () => {
    const body = { month: calendar.nextMonth, year: calendar.nextYear };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);

    setDates([ ...dates ]);
    setCalendar({
      ...calendar,
      month: calendar.nextMonth,
      year: calendar.nextYear,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear
    });
  }

  const onClickPrevious = () => {
    const body = { month: calendar.previousMonth, year: calendar.previousYear };
    const { dates, nextMonth, nextYear, previousMonth, previousYear } = datesGenerator(body);

    setDates([ ...dates ]);
    setCalendar({
      ...calendar,
      month: calendar.previousMonth,
      year: calendar.previousYear,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear
    });
  }

  const onSelectDate = (date) => {
    setSelectedDate(new Date(date.year, date.month, date.date))
  }

  return (
    <div className='calenderpage'>
    <Headhome />
    <div className='calenderItem'>
      <Container className='container'>
        <div className='header'>
            <div className='date'>
                <MonthText className='headerDate'>
                    <div className='months'>{months[calendar.month]}</div>
                    <div className='year'>{calendar.year}</div>
                </MonthText>
            </div>
            <div className='btn_move'>
                <div onClick={onClickPrevious}  className='left'></div>
                <div onClick={onClickNext}  className='right'></div>
            </div>
        </div>
        <div>
          
            <div>
              <table style={{ width: '100%' }}>
                <tbody>
                  <tr>
                    {days.map((day) => (
                      <td className='weeks' key={day} style={{ padding: '0px 0' }}>
                        <div className='week' style={{ textAlign: 'center', fontSize:'1.07rem' }}>
                          {day}
                        </div>
                      </td>
                    ))}
                  </tr>

                  {dates.length > 0 && dates.map((week) => (
                    <tr key={JSON.stringify(week[0])}>
                      {/* {week.map((each) => (
                        <td key={JSON.stringify(each)} style={{ padding: '10px 10px' }}>
                          <div onClick={() => onSelectDate(each)} style={{ textAlign: 'center', padding: '10px 10px',fontSize:'20px', color: each.month === calendar.month ? '#000000' : '#E5E5E5',
                             borderRadius: selectedDate.toDateString() === new Date(each.year, each.month, each.date).toDateString() ? '8px' : '0', // Apply border radius only to the selected date
                            //  backgroundColor: selectedDate.toDateString() === new Date(each.year, each.month, each.date).toDateString() ? 'yellow' : 'transparent'
                            border: selectedDate.toDateString() === new Date(each.year, each.month, each.date).toDateString() ? '1px solid pink' : 'none',
                        }}>
                            {each.date}
                          </div>
                        </td>
                      ))} */}
                      {week.map((each) => (
                        <td key={JSON.stringify(each)} style={{ padding: '0px 0px' }}>
                          <DateCell
                            isselected={selectedDate.toDateString() === new Date(each.year, each.month, each.date).toDateString()}
                            iscurrentMonth={each.month === calendar.month}
                            isPast={new Date(each.year, each.month, each.date) < new Date()} // Check if the date is in the past
                            isFuture={new Date(each.year, each.month, each.date) > new Date()} // Check if the date is in the future
                            onMouseEnter={() => console.log('Mouse enter')} // Add your logic for displaying the <abbr> tag
                            onMouseLeave={() => console.log('Mouse leave')} // Add your logic for hiding the <abbr> tag
                            data-tooltip="Your Tooltip" // Replace with the actual tooltip text
                            onClick={() => onSelectDate(each)}
                           className='day'>
                            {each.date}
                          </DateCell>
                        </td>
                      ))}

                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        </div>
      </Container>
    </div>
    </div>
  );
}

export default CustomCalender;



// color: each.month === calendar.month ? (selectedDate.toDateString() === new Date(each.year, each.month, each.date).toDateString() ? 'blue' : '#000000') : '#E5E5E5',
// borderRadius: selectedDate.toDateString() === new Date(each.year, each.month, each.date).toDateString() ? '5px' : '0', // Apply border radius only to the selected date
// backgroundColor: selectedDate.toDateString() === new Date(each.year, each.month, each.date).toDateString() ? 'yellow' : 'transparent' // Apply background color only to the selected date