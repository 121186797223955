import React from "react";
import './pricepage.scss';
const PricePage = () => {
    return( 
        <div className="price">
            {/* <Headhome /> */}
            <div className="content">
                <div className="text1">Pricing</div>
                <div className="text2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
                <div className="plan">
                    <div className="paid_plan">
                        <div className="text6">Paid</div>
                        <div className="text7">Limitless possibilities</div>
                        <div className="paid">
                            <h1 className="value">202kr/må</h1>
                            <p className="text3">Try as long you like</p>
                            <div className="textlist">
                                <div className="texts">
                                    <div className="icon">✓</div>
                                    <label className="text4">Motta tekstmeldinger ved bestillinger</label>
                                </div>
                                <div className="texts">
                                    <div className="icon">✓</div>
                                    <label className="text4">Tilgang til Admin Dashboard</label>
                                </div>
                                <div className="texts">
                                    <div className="icon">✓</div>
                                    <label className="text4">Bestillingsoppfølging og statusoppdateringer KI</label>
                                </div>
                            </div>
                            <button className="submit">Sign Up</button>
                        </div>
                    </div>
                    <div className="plan_unlimited">
                        <div className="text6">Unlimited</div>
                        <div className="text7">Limitless possibilities</div>
                        <div className="unlimited">
                            <h1 className="value">808kr/må</h1>
                            <p className="text3">Try as long you like</p>
                            <div className="textlist">
                                <div className="left">
                                    <div className="texts">
                                        <div className="icon">✓</div>
                                        <label className="text4">Tilgang til Nikolas App</label>
                                    </div>
                                    <div className="texts">
                                        <div className="icon">✓</div>
                                        <label className="text4">Automatisert Booking KI</label>
                                    </div>
                                    <div className="texts">
                                        <div className="icon">✓</div>
                                        <label className="text4">Verifisering med Kulturelle Telefonnumre</label>
                                    </div>
                                </div>
                                <div className="right">
                                    <div className="texts">
                                        <div className="icon">✓</div>
                                        <label className="text4">Avanserte Verifisering metoder</label>
                                    </div>
                                    <div className="texts">
                                        <div className="icon">✓</div>
                                        <label className="text4">Tilpassbare bestillingsskjemaer</label>
                                    </div>
                                    <div className="texts">
                                        <div className="icon">✓</div>
                                        <label className="text4">+ Generelle KI Tjenester for Variasjon</label>
                                    </div>
                                </div>
                            </div>
                            <button className="submit">Sign Up</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricePage;