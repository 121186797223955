import React from "react";
import SideBar from "../component/sidebar";
import RightBar from "../component/rightbar";
import MainDashboard  from '../component/maindashboard'
function DashBoard (){
    return(
        <div className="dashboard">
            <SideBar/>
            <MainDashboard />
            <RightBar />
        </div>
    )
}

export default DashBoard;
