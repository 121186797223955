import React from "react";
import './timetable.scss';
const TimeTable = () =>{
    return (
        <div className="timetableitem">
            <div className="timetable">
                <div className="top">
                    <div className="title">
                        <div className="title_text">Select a time that fits you!</div>
                        <div className="icons">
                            <div className="left"></div>
                            <div className="right"></div>
                        </div>
                    </div>
                    <hr className="line"></hr>
                </div>
                <p className="text1">Recommended</p>
                <div className="listbtn">
                    <button className="btnbook unselect">18:00</button>
                    <button className="btnbook unselect">7:00</button>
                    <button className="btnbook">Reserved 08:00</button>
                    <button className="btnbook">Reserved 09:00</button>
                    <button className="btnbook unselect">10:00</button>
                    <button className="btnbook unselect">10:00</button>
                </div>
                <button className="btn">Book Now</button>
            </div>
        </div>
    )
}

export default TimeTable;