import React from "react";
import img_logo from '../assests/logo.png'
// import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import {Icon} from 'react-icons-kit';
// import {eyeOff} from 'react-icons-kit/feather/eyeOff';
// import {eye} from 'react-icons-kit/feather/eye'
// import { Link } from "react-router-dom";
function SetUp(){
    // const [password, setPassword] = useState("");
    // const [type, setType] = useState('password');
    // const [icon, setIcon] = useState(eyeOff);
    const navigate = useNavigate()
        // const handleToggle = () => {
        //     if (type==='password'){
        //        setIcon(eye);
        //        setType('text')
        //     } else {
        //        setIcon(eyeOff)
        //        setType('password')
        //     }
        //  }
    const handlesubmit = (e)=>{
        e.preventDefault()
        console.log('submit')
        navigate('/dashboard')
    }
    return(
        <div className="setup">
            <div className="container">
                <div className="content">
                    <div className="login_content">
                        <div className="label_signin">
                        Finish setting up 
                        </div>
                        <div className="label_signin">
                        your business !
                        </div>
                        <div className="label_lorem">
                        Setup your buisness with a couple of questions.
                        </div>
                    </div>
                    <div className="form">
                        <div className="username">
                            <p className="label">Company Name</p>
                            <input  className="input_username" placeholder="Enter company name"/>
                        </div>
                        <div className="username">
                            <p className="label">Default business hours</p>
                            <input  className="input_username" placeholder="Ex: mon-fri: 12:00 - 19:00, sat: 10:00 - 16:00, sun: closed"/>
                        </div>
                        <div className="username">
                            <p className="label">What Service does your business offer?</p>
                            <input  className="input_username" placeholder="Ex: short hair cut, long hair cut, facial, "/>
                        </div>
                        <button className="submit" onClick={handlesubmit}>Continue to Dashboard</button>
                    </div>
                </div>
                <div className="logo">
                    <img src={img_logo} alt="img" className="img"/> 
                </div>
            </div>
        </div>
    )
}

export default SetUp;

