import React from "react";
import './additioninformation.scss';
const AdditionInformation = () =>{
    return (
        <div className="additionitem">
            <div className="addition">
                <div>
                    <div className="title">Service 1</div>
                    <hr className="line"></hr>
                </div>
                <div className="content">
                    <div className="text1">
                        <div className="icon"></div>
                        <div className="text12">1hour ¤ 400kr(price from the input)</div>
                    </div>
                    <div className="texts">Service description from the input. ex: "Hos oss, i [Firmanavn], kan du oppleve hårfargingsmagi! Vår hårblekingstjeneste gir deg muligheten til å lysne håret ditt med ekspertise og omsorg. Vanligvis tar prosessen omtrent 8 timer, hvor vi nøye overvåker og sikrer optimal blekeffekt. Ta steget inn i en verden av forvandling hos [Firmanavn] og la oss skape den perfekte, lysende nyansen for deg!"</div>
                </div>
                <button className="btn">Select service</button>
            </div>
        </div>
    )
}

export default AdditionInformation;