import React from "react";
import './verification.scss';
const VerificationItem = () =>{
    return (
        <div className="verifyitem">
            <div className="verification">
                <div className="top">
                    <div className="title">
                        <div className="title_text">Verification</div>
                        <div className="icons">
                            <div className="left"></div>
                            <div className="right"></div>
                        </div>
                    </div>
                    <hr className="line"></hr>
                </div>
                <div className="content">
                    <div className="text1">To prevent false bookings please type in your phone number and you will recive an verification code</div>
                    <div className="inputs">
                        <div className="label">Phone Number</div>
                        <input className="input"/>
                    </div>
                </div>
                <button className="btn">Book Now</button>
            </div>
        </div>
    )
}

export default VerificationItem;