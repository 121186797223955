import React from "react";
// import logo1 from '../assests/images/ClockCounterClockwise.svg'
// import logo from '../assests/images/Vector.svg'
// import panel from '../assests/panel.svg'
// import img_star from '../assests/star.svg'
// import img_sun  from '../assests/sun.svg'
// import img_clock from '../assests/clock.svg'
// import img_alarm  from '../assests/alarm.svg'
function Header(){
    return(
        <div className="header">
            <div className="left">
                <div className="logo">
                    {/* <img src={panel} alt="img" className="img"/> 
                    <img src={img_star} alt="img" className="img"/>  */}
                </div>
                <div className="url">
                    DashBoard   /   Default
                </div>
            </div>
            <div className="right">
                <div className="search">
                    <input />
                </div>
                <div className="logo">
                    {/* <img src={img_sun} className="img" alt="img"/> 
                    <img src={img_clock} className="img" alt="img"/>
                    <img src={img_alarm} className="img" alt="img"/>
                    <img src={panel} className="img" alt="img"/> */}
                </div>
            </div>
        </div>
    )
}

export default Header;