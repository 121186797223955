import React from "react";
import Header from "./header";
import Card from "./card";

function MainDashbard(){
    return(
        <div className="main_dashboard">
            <Header />
            <div className="frame">
                <div className="today">
                    Today
                </div>
                <div className="main_frame">
                    <div className="cards">
                        <Card />
                        <Card />
                        <Card />
                        <Card />
                    </div>
                    <div className="display_block">
                        <div className="block">
                            <div className="title">Display reservations here</div>
                            <div className="lists"> 
                                <div className="each">
                                    <div className="time">Service: 1, 20.11.2023 17:00 </div>
                                    <div className="selection">
                                        <div className="reschedule">Reschedule | </div>
                                        <div className="cancel"> Cancel</div>
                                    </div>
                                </div>
                                <div className="each">
                                    <div className="time">Service: 1, 20.11.2023 17:00 </div>
                                    <div className="selection">
                                        <div className="reschedule">Reschedule | </div>
                                        <div className="cancel"> Cancel</div>
                                    </div>
                                </div>
                                <div className="each">
                                    <div className="time">Service: 1, 20.11.2023 17:00 </div>
                                    <div className="selection">
                                        <div className="reschedule">Reschedule | </div>
                                        <div className="cancel"> Cancel</div>
                                    </div>
                                </div>
                                <div className="each">
                                    <div className="time">Service: 1, 20.11.2023 17:00 </div>
                                    <div className="selection">
                                        <div className="reschedule">Reschedule | </div>
                                        <div className="cancel"> Cancel</div>
                                    </div>
                                </div>
                                <div className="each">
                                    <div className="time">Service: 1, 20.11.2023 17:00 </div>
                                    <div className="selection">
                                        <div className="reschedule">Reschedule | </div>
                                        <div className="cancel"> Cancel</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="chart_block">
                            Chart
                        </div>
                    </div>
                    <div className="reservation">
                        <div className="chart_block">
                            chart
                        </div>
                        <div className="block">
                            block
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainDashbard;