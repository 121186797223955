import React from 'react';

const Overview = () => {
  return (
    <div className='home'>
      <h1>Overview</h1>
    </div>
  );
};

export default Overview;
