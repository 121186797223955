import React from "react";

function RightBar(){
    return(
        <div className="rightbar">
            <div>Right Bar</div>
            <div>
                <div>
                    <div>New Reservation</div>
                    <div>Just now</div>
                </div>
                <div>
                    <div>New Reservation</div>
                    <div>59 minutes ago</div>
                </div>
                <div>
                    <div>New Reservation</div>
                    <div>12 hours ago</div>
                </div>
                <div>
                    <div>New Reservation</div>
                    <div>Today, 11:59 AM    </div>
                </div>
            </div>
        </div>
    )
}

export default RightBar