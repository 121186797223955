import React from "react";
import { useNavigate } from "react-router-dom";
import img_side from '../assests/side.png'
import './headhome.scss';
const Headhome = () => {
    const navigate = useNavigate()
    const handlelogin = (e) => {
        navigate('/signin')
    }
    const handleSignup = (e) =>{
        navigate('/signup')
    }
    return(
        <div className="headhome">
            <div className="header">
                <img className="img_side" src={img_side} alt="img" />
                <div className="person">Vertio</div>
                <div className="feature">
                    <p className="tab">Features</p>
                    <p className="tab">Pricing</p>
                    <p className="tab">About Us</p>
                </div>
                <div className="button">
                    <div className="btn" onClick={handlelogin}>Logg inn</div>
                    <div className="btn select" onClick={handleSignup}>Registrer</div>
                </div>
            </div>
        </div>
    )
}

export default Headhome;