import React from "react";
import './otp.scss';
const OtpItem = () =>{
    return (
        <div className="otpitem">
            <div className="otp">
                <div className="top">
                    <div className="title">
                        <div className="title_text">OTP</div>
                        <div className="icons">
                            <div className="left"></div>
                            <div className="right"></div>
                        </div>
                    </div>
                    <hr className="line"></hr>
                </div>
                <div className="content">
                    <div className="text1">We have sent the verification code to +47 xxx xx xxx</div>
                    <div className="inputs">
                        <input className="input"/>
                        <input className="input"/>
                        <input className="input"/>
                        <input className="input"/>                                                                              
                    </div>
                    <div className="text2">Didn’t recive code? (60sec)</div>
                </div>
                <button className="btn">Book Now</button>
            </div>
        </div>
    )
}

export default OtpItem;