import React from "react";

function SideBar(){
    return(
        <div className="sidebar">
            <div className="profile_header">
                <div className="profile_icon"></div>
                <div>COMPANY NAME</div>
            </div>
            <div className="frequency">
                <div className="favorites">Favorites</div>
                <div className="recently">Recently</div>
            </div>

            <div>
                <div>Overview</div>
                <div>Projects</div>
            </div>
            <div className="dash">
                <div>Dashboards</div>
                <div className="content">
                    <div className="analytics">
                        <div className="analytics_icon"></div>
                        <div>Analytics</div>
                    </div>
                    <div className="alerts">
                        <div className="alert_icon"></div>
                        <div className="">Alerts</div>
                    </div>
                    <div className="profile">
                        <div className="profile_icon"></div>
                        <div>Profile</div>
                    </div>
                    <div className="app">
                        <div className="app_icon"></div>
                        <div>App</div>
                    </div>
                </div>
            </div>
            <div className="pages">
                <div>Pages</div>
                <div className="lists">
                    <div className="userprofile">
                        <div className="header">
                            <div className="profile_icon"></div>
                            <div>User Profile</div>
                        </div>
                        <div className="content">
                            <div>Overview</div>
                            <div>Reservation</div>
                            <div>Campaigns</div>
                            <div>Documents</div>
                            <div>App</div>
                        </div>
                    </div>
                    <div className="account">
                        <div className="account_icon"></div>
                        <div className="">Account</div>
                    </div>
                    <div className="corporate">
                        <div className="corporate_icon"></div>
                        <div className="">Corporate</div>
                    </div>
                    <div className="blog">
                        <div className="blog_icon"></div>
                        <div className="">Blog</div>
                    </div>
                    <div className="social">
                        <div className="social_icon"></div>
                        <div className="">Social</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SideBar