import React from "react";

function Card(){
    return(
        <div className="card">
            <p className="title">Reservations Today</p>
            <p className="count">10</p>
        </div>
    )
}

export default Card;