import React from "react";
import Works from "../component/works";
import './workpage.scss';
const WorkPage = () => {
    return(
        <div className="workpage">
            {/* <Headhome /> */}
            <Works />
        </div>
    )
}

export default WorkPage;