import React from "react";
import rect from '../assests/rect.png'
import img_uppor_arrow  from '../assests/uppor_arrow.png'
import img_down_arrow from '../assests/down_arrow.png'
import './works.scss'
const Works= () => {
    return(
        <div className="works">
            <div className="text">How it work</div>
            <div className="content">
                <div className="each1">
                    <div className="baes_img">
                        <img src={rect} alt="img" className="img_base_1"/>
                    </div>
                    <div className="texts">
                        <p className="text1">Your Content Goes Here</p>
                        <p className="text2">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        {/* <img src={img_uppor_arrow} alt="img" className="img_arrow1"/> */}
                        <div className="img_arrow1"></div>
                    </div>
                </div>
                <div className="each2">
                    <img src={rect} alt="img"/>
                    <div className="texts">
                        <p className="text1">Your Content Goes Here</p>
                        <p className="text2">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        {/* <img src={img_down_arrow} alt="img" className="img_arrow2"/> */}
                        <div className="img_arrow2"></div>
                    </div>
                </div>
                <div className="each3">
                    <img src={rect} alt="img"/>
                    <div className="texts">
                        <p className="text1">Your Content Goes Here</p>
                        <p className="text2">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                    </div>
                </div>
            </div>
            <button className="submit">Create account</button>
        </div>
    )
}

export default Works;