import React from "react";
import './calender.scss';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
const CalenderPage = () =>{
    return(
        <div className="calenderitem">
            <div className="calender">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar showDaysOutsideCurrentMonth fixedWeekNumber={6} />
                </LocalizationProvider>
            </div>
        </div>
    )
}


export default CalenderPage;