import React from "react";
import img_dashboard  from '../assests/dashboard.svg'
// import CustomFooter from "../component/footer";
// import Works from "../component/works";
import './home.scss';
import Headhome from "../component/headhome";
import WorkPage from "./WorkPage";
import PricePage from "./PricePage";
import CustomFooter from "../component/footer";
// import red from '../assests/red.svg'
// import yellow from '../assests/yellow.svg'
// import green from '../assests/green.svg'
// import line from '../assests/line.svg'
const HomePage = ()=>{
    return(<div className="home">
        <Headhome />
        <div className="content">
            <div className="hero">
                <div className="text0">Et avansert bestillingssystem for din bedrift</div>
                <div className="text1">
                    <div className="text11">Optimaliser din Bestillingsprosess</div>
                    <div className="text12">med <span className="color_text">Avansert Teknologi</span></div>             
                </div>
                <button className="btn">Create account</button>
                <div className="text">
                    <span>Effektiviser Dine Operasjoner</span>
                    <span>|</span>
                    <span>Ingen Bryderi, Ingen Bekymringer</span>
                </div>
            </div>
            <div className="dash">
                {/* <div className="img"></div> */}
                <img src={img_dashboard} alt="img" className="img"/>
                {/* <img src={line} alt="img" className="line"/> */}
                {/* <img src={red} alt="img" className="red"/>
                <img src={yellow} alt="img" className="yellow"/>
                <img src={green} alt="img" className="green"/> */}
            </div>       
        </div>
        <WorkPage />
        <PricePage />
        <CustomFooter />
    </div>)
}

export default HomePage;
