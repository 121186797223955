import React from "react";
import img_logo from '../assests/logo.png'
import { useNavigate } from "react-router-dom";
function SetUptwo(){
    const navigate = useNavigate()
    const handlesubmit = (e)=>{
        e.preventDefault()
        console.log('submit')
        navigate('/dashboard')
    }
    return(
        <div className="setuptwo">
            <div className="container">
                <div className="content">
                    <div className="login_content">
                        <div className="label_signin">
                        Finish setting up 
                        </div>
                        <div className="label_signin">
                        your business !
                        </div>
                        <div className="label_lorem">
                        Setup your buisness with a couple of questions.
                        </div>
                    </div>
                    <div className="form">
                        <div className="username">
                            <p className="label">How many diffrent services does your buisness offer?</p>
                            <input  className="input_username" placeholder="DROPDOWN SELECTION (<SELECT><option></SELECT>)"/>
                            <p>x inputs will be determied by the selected option in the drop down</p>
                        </div>
                        <div className="service">
                            <div className="row">
                                <div className="each">
                                    <p className="text">Service 1</p>
                                    <input className="input"/>
                                </div>
                                <div className="each">
                                    <p className="text">Service 1</p>
                                    <input className="input"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="each">
                                    <p className="text">Service 1</p>
                                    <input className="input"/>
                                </div>
                                <div className="each">
                                    <p className="text">Service 1</p>
                                    <input className="input"/>
                                </div>
                            </div>
                        </div>
                        <button className="submit" onClick={handlesubmit}>Continue to Dashboard</button>
                    </div>
                </div>
                <div className="logo">
                    <img src={img_logo} alt="img" className="img"/> 
                </div>
            </div>
        </div>
    )
}

export default SetUptwo;

