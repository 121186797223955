import React from "react";
import img_logo from '../assests/logo.png'
// import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import CreditCardInput from 'react-credit-card-input';
import img_lock  from '../assests/lock.png'

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import 'bootstrap/dist/css/bootstrap.css';
// import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';

// import CountrySelect from 'react-bootstrap-country-select';
// import {
//     CitySelect,
//     CountrySelect,
//     StateSelect,
//     LanguageSelect,
//   } from "react-country-state-city";
// import "react-country-state-city/dist/react-country-state-city.css";

// import { Link } from "react-router-dom";
function PayMent(){
    // const [cardNumber, setcardNumber] = useState('');
    // const [expiry, setexpiry] = useState('');
    // const [cvc, setcvc] = useState('');
    const [ value, setValue ] = React.useState(null);
    // const [countryid, setCountryid] = useState(0);
    // const [stateid, setstateid] = useState(0);
    const navigate = useNavigate()
    const handlesubmit = (e)=>{
        e.preventDefault()
        console.log('submit')
        navigate('/dashboard')
    }
    return(
        <div className="billing">
            <div className="container">
                <div className="content">
                    <div className="login_content">
                        <div className="label_signin">
                        Finish setting up 
                        </div>
                        <div className="label_signin">
                        your business !
                        </div>
                        <div className="label_lorem">
                        To finish setting up your business.
                        </div>
                    </div>
                    <div className="form">
                        <div className="bill">
                            <div>Billed annually - 20%</div>
                            <div>Billed monthly</div>
                        </div>
                        <div>Billing information</div>
                        <div className="information">
                            <div className="email">
                                <div className="left">Email</div>
                                <div className="right">asankauiux.com@gmail.com</div>
                                {/* <input className="input" /> */}
                            </div>
                            <div className="address">
                                <div className="left">Address</div>
                                <div className="right">1234 Test Dr, Clearwater, FL</div>
                                {/* <input className="input"/> */}
                            </div>
                            <div className="city">
                                <div className="left">City</div>
                                <div className="right">Clearwater</div>
                                {/* <input /> */}
                            </div>
                            <div className="state_zip">
                                <div className="left">State</div>
                                <div className="right">
                                    <div className="state">
                                        <div className="state_select">
                                            <select className="select">
                                                <option value="A">A</option>
                                                <option value="B">B</option>
                                                <option value="-">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="zip">
                                        <div>ZIP</div>
                                        <div>10000    </div>
                                        {/* <input  className="zip_input"/> */}
                                    </div>
                                </div>

                            </div>
                            <div className="country">
                                <div className="left">Country</div>
                                <div className="right">
                                    {/* <CountrySelect
                                    value={value}
                                    onChange={setValue}
                                    /> */}
                                </div>
                            </div>
                        </div>
                        <div className="payment_text">
                            <div>Payment information</div>
                            <div className="secure">
                                <img src={img_lock} alt="img" className="image"/>
                                <div>Secured by Stripe</div>
                            </div>
                        </div>
                        <div className="card_payment">
                            {/* <CreditCardInput
                            onError={({ inputName, err }) => console.log(`credit card input error: ${err}`)}
                            cardCVCInputProps={{
                                onBlur: e => console.log('cvc blur', e),
                                onChange: e => console.log('cvc change', e),
                                onError: err => console.log(`cvc error: ${err}`)
                            }}
                            cardExpiryInputProps={{
                                onBlur: e => console.log('expiry blur', e),
                                onChange: e => console.log('expiry change', e),
                                onError: err => console.log(`expiry error: ${err}`)
                            }}
                            cardNumberInputProps={{
                                onBlur: e => console.log('number blur', e),
                                onChange: e => console.log('number change', e),
                                onError: err => console.log(`number error: ${err}`)
                            }}
                            /> */}
                        </div>
                        <button className="submit" onClick={handlesubmit}>Pay 1$</button>
                    </div>
                </div>
                <div className="logo">
                    <img src={img_logo} alt="img" className="img"/> 
                </div>
            </div>
        </div>
    )
}

export default PayMent;

