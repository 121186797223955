import React from "react";
import './success.scss';
const SuccessItem = () =>{
    return (
        <div className="successitem">
            <div className="success">
                <div className="top">
                    <div className="icon"></div>
                    <div className="title">SERVICE 1 Booked!</div>
                    <div className="text0">20.11.2023 16:00</div>
                    <hr className="line"></hr>
                </div>
                <div className="content">
                    <div className="texts">
                        <div className="text1">Ref Number</div>
                        <div className="text2">000857621027</div>                                                                          
                    </div>
                    <div className="texts">
                        <div className="text1">Ref Number</div>
                        <div className="text2">000857621027</div>                                                                          
                    </div>
                    <div className="texts">
                        <div className="text1">Ref Number</div>
                        <div className="text2">000857621027</div>                                                                          
                    </div>
                    <div className="texts">
                        <div className="text1">Ref Number</div>
                        <div className="text2">000857621027</div>                                                                          
                    </div>
                </div>
                <button className="btn">Go Home</button>
            </div>
        </div>
    )
}

export default SuccessItem;