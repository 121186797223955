import React from "react";
import circle from '../assests/circle.png'
import video from '../assests/video.png'
import './footer.scss'
const CustomFooter = () => {
    return(
        <div className="footer">
            <p className="top-line"></p>
            <div className="container">
                <div className="left">
                    <img src={circle}  className="image" alt="img"/>
                    <div className="content">
                        <div className="text1">Vertio</div>
                        <div className="text2">Avanserte Kommunikasjonstjenester med ki for små og mellomstore bedrifter! 🚀🌐💼</div>
                    </div>
                </div>
                <div className="right">
                    <div className="paragraph1">
                        <p className="text">Company</p>
                        <p className="text">Blog</p>
                        <p className="text">Careers</p>
                        <p className="text">Pricing</p>
                    </div>
                    <div className="paragraph2">
                        <p className="text">Resources</p>
                        <p className="text">Documentation</p>
                        <p className="text">Papers</p>
                        <p className="text">Press Conferences</p>
                    </div>
                    <div className="paragraph3">
                        <p className="text">Legal</p>
                        <p className="text">Terms of Service</p>
                        <p className="text">Privacy Policy</p>
                        <p className="text">Cookies Policy</p>
                    </div>
                </div>
            </div>
            {/* <p className="line"></p> */}
            <div className="bottom">
                <p className="text1">© 2023 Vertio. All rights reserved.</p>
                {/* <img src={video} alt="img" className="image"/> */}
            </div>
        </div>
    )
}

export default CustomFooter;