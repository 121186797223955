import React from "react";
import './service.scss';
const ServiceItem = () =>{
    return (
        <div className="serviceitem">
            <div className="service">
                <div className="top">
                    <div className="title">
                        <div className="title_text">Choose Service</div>
                    </div>
                    <hr className="line"></hr>
                </div>
                <div className="radios">
                    <div className="radioitem">
                        <div className="texts">
                            <div className="text1">SERVICE 1</div>
                            <div className="text2">read more about this service</div>
                        </div>
                        <div className="input">
                            <input type="radio" className="radio-input"/>
                        </div>
                    </div>
                    <div className="radioitem">
                        <div className="texts">
                            <div className="text1">SERVICE 1</div>
                            <div className="text2">read more about this service</div>
                        </div>
                        <div className="input">
                            <input type="radio" className="radio-input"/>
                        </div>
                    </div>
                    <div className="radioitem">
                        <div className="texts">
                            <div className="text1">SERVICE 1</div>
                            <div className="text2">read more about this service</div>
                        </div>
                        <div className="input">
                            <input type="radio" className="radio-input"/>
                        </div>
                    </div>
                </div>
                <button className="btn">Book Now</button>
            </div>
        </div>
    )
}

export default ServiceItem;