import './sign.scss'
// import logo from '../assests/logo.png'
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'
import { Link } from "react-router-dom";
const SigninPage = () => {
    const [password, setPassword] = useState("");
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    const navigate = useNavigate()
    const handleToggle = () => {
        if (type==='password'){
           setIcon(eye);
           setType('text')
        } else {
           setIcon(eyeOff)
           setType('password')
        }
     }
    const handlesubmit = (e)=>{
        e.preventDefault()
        console.log('submit')
        navigate('/dashboard')
    }
    return(
        <div className='sign'>
            <div className='name'>Vertio</div>
            <div className="signin">
                <div className="content">
                    <div className="login_content">
                        <div className="label_wel">
                            Welcome !
                        </div>
                        <div className="label_signin">
                            Sign in to
                        </div>
                        <div className="label_lorem">
                            Lorem Ipsum is simply
                        </div>
                    </div>
                    <div className="form">
                        <div className="username">
                            <p className="label">Email</p>
                            <input  className="input_username" placeholder="Enter your email"/>
                        </div>
                        <div className="password">
                            <div className="label">Password</div>
                            <div className="content_password">
                                <input
                                    type={type}
                                    name="password"
                                    placeholder="Enter your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    autoComplete="current-password"
                                    className="input_password"
                                />
                                <span className="eye" onClick={handleToggle}>
                                    <Icon class="absolute mr-10" icon={icon} size={18}/>
                                </span>
                            </div>
                        </div>
                        <div className="forgot_password">
                            <div className="check">
                                <input type="checkbox" />
                                <div className="label">Remember</div>
                            </div>
                            <div className="forgot">Forgot password?</div>
                        </div>
                        <button className="submit" onClick={handlesubmit}>Login</button>
                        <div className="register">
                            <p className='isaccount'>Don’t have an Account? </p>
                            <Link className="link" to='/signup'>
                                <p className="regis">Register</p>
                            </Link>

                        </div>
                    </div>
                </div>
                <div className="logo">
                    <div className='img'> </div>
                    {/* <img src={logo} alt="logo" className='img'/> */}
                </div>
            </div>
        </div>
    )
}

export default SigninPage
