import './App.scss';
import DashBoard from './pages/dashbard';
// import Signin from './pages/login';
import { Route, Routes } from 'react-router-dom';
// import SignUp from './pages/signup';
import SetUp from './pages/setup'
import SetUptwo from './pages/setuptwo';
import PayMent from './pages/payment';
import HomePage from './pages/HomePage';
import WorkPage from './pages/WorkPage';
import PricePage from './pages/PricePage';
import CustomFooter from './component/footer';
import SigninPage from './pages/signin';
import SignUpPage from './pages/signuppage';
import CalenderPage from './pages/calenderPage';
import ServiceItem from './component/service';
import TimeTable from './component/tmetable';
import VerificationItem from './component/verification';
import AdditionInformation from './component/additioninfromation';
import SuccessItem from './component/success';
import OtpItem from './component/otp';
import CustomCalender from './component/customcalender';
import CustomSidebar from './component/Sideitems/sidebar';
import Overview from './pages/dash/Overview';
import Team from './pages/dash/Team';
import { Reports } from './pages/dash/Reports';

function App() {
  return (
    <Routes>
      <Route path='/signin' element={<SigninPage />} />
      <Route path='/signup' element={<SignUpPage />} />
      <Route path='/companyname-setup' element={<SetUp />} />
      <Route path='/setuptwo' element={<SetUptwo />} />
      <Route path='/payment' element={<PayMent />} />
      <Route path='/work' element={<WorkPage />} />
      <Route path='/price' element={<PricePage />} />
      <Route path='/calender' element={<CalenderPage />} />
      <Route path='/service' element={<ServiceItem />} />
      <Route path='/timetable' element={<TimeTable />} />
      <Route path='/verify' element={<VerificationItem />} />
      <Route path='/otp' element={<OtpItem />} />
      <Route path='/success' element={<SuccessItem />} />
      <Route path='/cal' element={<CustomCalender />} />
      <Route path='/addinfo' element={<AdditionInformation />} />
      <Route path='/footer' element={<CustomFooter />} />
      <Route path='/dashboard' element={<DashBoard />}/>  
      <Route path='/side' element={<CustomSidebar />}/>
      <Route path='/overview' element={<Overview />}/>
      <Route path='/reports' element={<Reports />}/>
      <Route path='/team' element={<Team />}/>  
      <Route path='/' element={<HomePage />}/>    
  </Routes>
  );
}


export default App;
